<template>
  <div class="region_compare_anji">
    <span class="compare-title">
      <div style="font-size: 16rem;font-weight:bold;float:left;  display: flex;align-items: center;height:40rem;"><img style="width:20rem;margin-right: 3rem;padding-left:10rem;" src="./img/notice.png" /><span>报警率</span></div>
    </span>
    <div ref="chartBar" class="compare-charts" v-loading="loading" element-loading-background="rgb(44, 75, 147)"/>
  </div>
</template>
<script>
import echarts from 'echarts'
import decBlockTitle from '../decorations/blockTitle2'
import {mapState} from "vuex";
import {
  getUserInfo, isEmptyObj
} from '@/util/index'

export default {
  name: "alarmAnjiCompare",
  components: {
    decBlockTitle,
  },
  props: {
    countyData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    streetData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    countyData: {
      handler: function (val) {
        this.getData()
      },
      deep: true
    },
    streetData: {
      handler: function (val) {
        this.getData()
      },
      deep: true
    }
  },
  created() {
   
  },
  computed: {
    ...mapState(['bigScreenDataStack'])
  },
  mounted() {
    this.getData()
  },
  data() {
    return {
      option: {},
      myChart: null,
      loading: true,
    };
  },
  methods: {
    getData() {
      this.loading = true
      if (isEmptyObj(this.countyData) || isEmptyObj(this.streetData))  {
        return
      }
      this.initLine() 
    },
    initLine() {
      this.myChart = echarts.init(this.$refs.chartBar)
      window.addEventListener('resize', () => {
        if (this.myChart) {
          this.myChart.resize();
        }
      })
      var onlineDeviceCount = []
      for (var j = 0; j < this.countyData.alarmData.length; j++) {
        this.countyData.alarmData[j].OnlineDeviceCount = 0
        for (var i = 0; i < this.streetData.streetData.length; i++) {
          if (this.streetData.streetData[i].AreaId == this.countyData.alarmData[j].AreaId) {
            this.countyData.alarmData[j].OnlineDeviceCount = this.streetData.streetData[i].OnlineDeviceCount
            onlineDeviceCount.push(this.countyData.alarmData[j].OnlineDeviceCount)
            break
          }
        }
      }
      onlineDeviceCount.sort()
      var data = []
      for(var i = 0; i < onlineDeviceCount.length; i++) {
        for(var j = 0; j < this.countyData.alarmData.length; j++) {
          if (onlineDeviceCount[i] == this.countyData.alarmData[j].OnlineDeviceCount) {
            data.unshift(this.countyData.alarmData[j])
            break
          }
        }
      }
      var dataStyle = { 
        normal: {
          label : {
            show: true,
            formatter: '{c}%'
          }
        }
      };
      this.option = {
          tooltip : {
            trigger: 'axis',
            axisPointer : { 
              type : 'shadow'
            }
          },
          legend: {
            data:['报警率', '合格率']
          },
          calculable : true,
          xAxis : [
            {
              min: 0,
              max: 100,
              type : 'value',
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false 
              },
              axisLabel: {
                formatter: '{value} %',
                color:'#D0D0D0'
              },
            }
          ],
          yAxis : [
            {
              axisLine: {
                lineStyle:{
                  color: '#324D4D',
                }
              },
              axisTick: {
                alignWithLabel: true,
                lineStyle:{
                  color: '#324D4D',
                }
              },
              axisLabel: {
                color:'#D0D0D0'
              },
              type : 'category',
              data : data.filter((item, index) => index < 4).map(item => {
                return item.Name
              }),
            }
          ],
          series : [
            {
              name:'报警率',
              type:'bar',
              itemStyle : dataStyle,
              stack: 'total',
              data: data.filter((item, index) => index < 4).map(item => {
                return item.OnlineDeviceCount == 0 ? 0: (item.Number * 100 / item.OnlineDeviceCount).toFixed(2)
              }),
              color: [
                '#E57C37',
              ]
            },
            {
              name:'合格率',
              type:'bar',
              itemStyle : dataStyle,
              stack: 'total',
              data: data.filter((item, index) => index < 4).map(item => {
                return item.OnlineDeviceCount == 0 ? 100 : ((item.OnlineDeviceCount - item.Number) * 100 / item.OnlineDeviceCount).toFixed(2)
              }),
              color: [
                '#5EBCC7',
              ]
            },
          ],
          legend: {
            textStyle: {
              color: '#D0D0D0' 
            }
          },
          grid: {
            left: 10,
            top: 30,
            right: 20,
            bottom: 10,
            containLabel: true
          }
      };
      this.myChart.setOption(this.option, true)
      this.loading = false
    },
    beforeDestroy () {
      window.removeEventListener('resize', () => {
        this.myChart.resize()
      })
    }
  },

};
</script>
<style lang="scss">
.region_compare_anji {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10rem;
}

.compare-title {
  padding-left: 20px;
  padding-top: 10px;
  font-size: 16rem;
  font-weight:bold;
}

.compare-charts {
  height: 14vh;
}

.box_compare{
  background: linear-gradient(#19253e, #223e61);  
  border-radius:16px;
}
.box_compare:hover {
  box-shadow: inset 0 0 32px #25a6ff;
}

</style>
